<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-overlay :show="isLoading">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                      <b-row>
                        <b-col lg="12" sm="12">
                          <b-row>
                            <b-col lg="8">
                              <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="fiscal_year_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('birtan.fiscal_year') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.fiscal_year_id"
                                      :options="fiscalYearList"
                                      id="fiscal_year_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                          <b-card
                              border-variant="success"
                              :header="$t('bsri_config.checklist') + ' ' + $n(index + 1)"
                              header-bg-variant="success"
                              header-text-variant="white"
                              v-for="(detail,index) in formData.checklist_details" :key="index" class="text-black mb-3">
  <!--                          <legend>{{ $t('bsri_config.checklist') }} {{ $n(index + 1) }}</legend>-->
                            <b-row>
                              <b-col xl="6" lg="6" sm="6">
                                <ValidationProvider name="Name (En)" vid="name" rules="required">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="name"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('globalTrans.name')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="name"
                                        v-model="detail.name"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback d-block" v-if="allErrors['checklist_details.'+ index + '.name']">
                                      {{ allErrors['checklist_details.'+ index + '.name'][0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col xl="6" lg="6" sm="6">
                                <ValidationProvider name="Name (Bn)" vid="name_bn" rules="required">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="name_bn"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('globalTrans.name_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="name_bn"
                                        v-model="detail.name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback d-block" v-if="allErrors['checklist_details.'+ index + '.name_bn']">
                                      {{ allErrors['checklist_details.'+ index + '.name_bn'][0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col xl="6" lg="6" sm="6">
                                <ValidationProvider name="Description (En)" vid="description" rules="">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="description"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('globalTrans.description')}}
                                    </template>
                                    <b-form-textarea
                                        id="description"
                                        v-model="detail.description"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback d-block" v-if="allErrors['checklist_details.'+ index + '.description']">
                                      {{ allErrors['checklist_details.'+ index + '.description'][0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col xl="6" lg="6" sm="6">
                                <ValidationProvider name="Description (Bn)" vid="description_bn" rules="">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="description_bn"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('globalTrans.description_bn')}}
                                    </template>
                                    <b-form-textarea
                                        id="description_bn"
                                        v-model="detail.description_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback d-block" v-if="allErrors['checklist_details.'+ index + '.description_bn']">
                                      {{ allErrors['checklist_details.'+ index + '.description_bn'][0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col xl="6" lg="6" sm="6">
                                <ValidationProvider name="Sorting Order" vid="sorting_order" rules="required">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="sorting_order"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{ $t('bsri_config.sorting_order') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="number"
                                        id="sorting_order"
                                        v-model="detail.sorting_order"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback d-block" v-if="allErrors['checklist_details.'+ index + '.sorting_order']">
                                      {{ allErrors['checklist_details.'+ index + '.sorting_order'][0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            <div class="mt-3">
                              <button @click="addItem" class="btn btn-sm btn-primary mr-2" type="button"
                              ><i class="fas fa-plus-circle m-0"></i> {{$t('globalTrans.add_more')}}</button>
                              <button @click="remove(index)" class="btn btn-sm btn-danger" type="button" v-if="formData.checklist_details.length > 1"><i class="fas fa-window-close m-0"></i> {{ $t('globalTrans.delete') }}</button>
                            </div>
                          </b-card>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-overlay>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { checklistStore, checklistUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
    }
  },
  mounted () {
    core.index()
    flatpickr('#datepicker', {})
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        fiscal_year_id: '0',
        checklist_details: [
          {
            name: '',
            name_bn: '',
            description: '',
            description_bn: '',
            sorting_order: ''
          }
        ]
      },
      allErrors: {},
      isLoading: false
    }
  },
  computed: {
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.isLoading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
          result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${checklistUpdate}/${this.id}`, this.formData)
      } else {
          result = await RestApi.postData(incentiveGrantServiceBaseUrl, checklistStore, this.formData)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
          this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
          this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
          })

          this.$bvModal.hide('modal-4')
      } else {
        this.allErrors = result.errors
        this.$refs.form.setErrors(result.errors)
      }
      this.isLoading = false
    },
    addItem () {
      const obj = {
        name: '',
        name_bn: '',
        description: '',
        description_bn: '',
        sorting_order: ''
      }
      const key1 = parseInt(this.formData.checklist_details.length - 1)
      const item = this.formData.checklist_details[key1]
      let isEmpty = false
      if (item.name === '' || item.name_bn === '' || item.sorting_order === '') {
        isEmpty = true
      }
      if (isEmpty === false) {
          this.formData.checklist_details.push(obj)
      }
    },
    remove (key) {
        this.formData.checklist_details.splice(key, 1)
    }
  }
}
</script>
<style scoped>
[dir="ltr"][mode="light"] .card {
  border: 1px solid;
}
</style>
