<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <div class="mb-4 text-black"><strong>{{ $t('globalTrans.fiscal_year') }}:</strong> {{ $i18n.locale === 'en' ?  fiscalYearObj.text_en : fiscalYearObj.text_bn }}</div>
                          <b-row>
                                <b-col lg="12" sm="12" class="text-center mb-4">
                                    <h5 class='complain-title'>{{ $t('globalTrans.details') }}</h5>
                                </b-col>
                          </b-row>
                          <b-table bordered hover :items="formData.checklist_details" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                            <template v-slot:cell(index)="data">
                              {{ $n(data.index + 1) }}
                            </template>
                          </b-table>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import ExportPdf from './export_pdf_details'

export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.formData = this.item
    this.fiscalYearObj = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0).find(fiscalYear => fiscalYear.value === this.formData.fiscal_year_id)
  },
  data () {
    return {
      formData: {},
      fiscalYearObj: {}
    }
  },
  computed: {
    localeLang () {
      return this.$i18n.locale
    },
    listData () {
      const objectData = this.item
      return objectData.filter(data => data.id === this.item.id)
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('globalTrans.name'), class: 'text-left' },
          { label: this.$t('globalTrans.description'), class: 'text-left' },
          { label: this.$t('bsri_config.sorting_order'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'name_bn' },
          { key: 'description_bn' },
          { key: 'sorting_order' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'name' },
          { key: 'description' },
          { key: 'sorting_order' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    pdfExport () {
      const reportTitle = this.$t('bsri_config.checklist') + ' ' + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail', 8, reportTitle, this.formData, this)
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
